import React, { useEffect, useState } from 'react';
import Styled from './Style';
import useChildren from '../../hooks/useChildren';

const InstagramGallery = props => {
  const instagramBlock = useChildren(props);
  const [photos, setPhotos] = useState([]);
  const accessToken = instagramBlock.token;
  var rows = 2;
  var cols = 6;
  if (
    !isNaN(
      parseFloat(instagramBlock.rows) && !isNaN(parseFloat(instagramBlock.cols))
    )
  ) {
    rows = instagramBlock.rows;
    cols = instagramBlock.cols;
  }
  const limit = rows * cols;

  let showImage = false;
  let showVideo = false;
  if (
    instagramBlock.content === 'Images' ||
    instagramBlock.content === 'Both'
  ) {
    showImage = true;
  }
  if (
    instagramBlock.content === 'Videos' ||
    instagramBlock.content === 'Both'
  ) {
    showVideo = true;
  }

  useEffect(() => {
    const fetchPhotos = async () => {
      try {
        const response = await fetch(
          `https://graph.instagram.com/me/media?fields=id,media_type,media_url,permalink,thumbnail_url&access_token=${accessToken}&limit=24`
        );
        const data = await response.json();

        // Filter based on showImage and showVideo
        let filteredPhotos = data.data.filter(photo => {
          if (
            photo.media_type === 'IMAGE' ||
            photo.media_type === 'CAROUSEL_ALBUM'
          ) {
            return showImage;
          }
          if (photo.media_type === 'VIDEO') {
            return showVideo;
          }
          return false;
        });

        filteredPhotos = filteredPhotos.slice(0, limit);

        setPhotos(filteredPhotos);
      } catch (error) {
        console.error('Error fetching Instagram photos:', error);
      }
    };

    fetchPhotos();
  }, [accessToken, showImage, showVideo, limit]);
  if (!photos.length) return null;
  return (
    <Styled.InstagramWrapper>
      <Styled.InstagramTitle>{instagramBlock.title}</Styled.InstagramTitle>
      <Styled.InstagramGallery style={{ '--cols': cols }}>
        {photos.map(photo => (
          <>
            {(photo.media_type === 'IMAGE' ||
              photo.media_type === 'CAROUSEL_ALBUM') &&
            showImage ? (
              <a
                key={photo.id}
                href={photo.permalink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={photo.media_url} alt="Instagram" />
              </a>
            ) : null}
            {photo.media_type === 'VIDEO' && showVideo ? (
              <a
                key={photo.id}
                href={photo.permalink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <video src={photo.media_url} controls />
              </a>
            ) : null}
          </>
        ))}
      </Styled.InstagramGallery>
    </Styled.InstagramWrapper>
  );
};

export default InstagramGallery;
